import React from 'react';
import { Alert } from './components/ui/alert';

const Error: React.FC = () => (
  <>
    <div style={{ paddingBottom: '20px' }} />
    <Alert status="error" title="Unable to process file">
      Please try again. If the problem persists, please contact MovieLabs.
    </Alert>
  </>
);

export default Error; 