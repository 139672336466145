import React from 'react';
import { Box, Center, Text, Link, Separator } from '@chakra-ui/react';

interface FooterProps {
  version?: string;
}

const Footer: React.FC<FooterProps> = ({ version }) => (
  <>
    <Separator />
    <Box as="footer" padding="20px">
      <Center color="gray.500">
      <Text fontSize="sm">© {new Date().getFullYear()} MovieLabs</Text>
      <Text>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</Text>
      {version && <Text fontSize="sm">OMC Checker v{version}</Text>}
      {!version && <Text fontSize="sm">OMC Checker</Text>}
      <Text>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</Text>
      <Link target="_blank" href="https://mc.movielabs.com/validator/user-guide.html" fontSize="sm">User Guide</Link>
      <Text>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</Text>
      <Link target="_blank" href="https://mc.movielabs.com/validator/eula.html" fontSize="sm">EULA</Link>
      </Center>
    </Box>
  </>
);

export default Footer; 