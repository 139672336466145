import React from 'react';
import { Column, useTable } from "react-table";
import { Table as ChakraTable, Code, Text } from '@chakra-ui/react';

export const columns: Column<any>[] = [
      {
        Header: 'Issue',
        accessor: 'issue',
      },
      {
        Header: 'What was Found',
        accessor: 'specifics',
      },
      {
        Header: 'JSON Pointer Types',
        accessor: 'jsonPointerTypes',
      },
      {
        Header: 'JSON Pointers',
        accessor: 'jsonPointers',
      },
    ];

export const Table: React.FC<{ columns: Column<any>[]; data: any[] }> = ({ columns, data }) => {
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
    } = useTable({ columns: columns as any, data });

  return (
    <ChakraTable.Root 
      {...getTableProps()} 
      size="sm" 
      stickyHeader 
      style={{ tableLayout: 'fixed', width: '100%' }} // Set table layout to fixed
    >
      <ChakraTable.Header>
        {headerGroups.map(headerGroup => (
          <ChakraTable.Row {...headerGroup.getHeaderGroupProps()} bg="bg.subtle" key="issues-header">
            {headerGroup.headers.map((column, index) => {
              let columnWidth;
              switch (index) {
                case 0:
                  columnWidth = '30%';
                  break;
                case 1:
                  columnWidth = '40%';
                  break;
                case 2:
                  columnWidth = '15%';
                  break;
                case 3:
                  columnWidth = '15%';
                  break;
                default:
                  columnWidth = '25%';
              }
              return (
                <ChakraTable.ColumnHeader 
                  {...column.getHeaderProps()} 
                  style={{ width: columnWidth }} // Apply the calculated width
                  key={`issues-header-${column.id}`}
                >
                  <Text fontWeight="bold">{column.render('Header')}</Text>
                </ChakraTable.ColumnHeader>
              );
            })}
          </ChakraTable.Row>
        ))}
      </ChakraTable.Header>
      <ChakraTable.Body {...getTableBodyProps()}>
        {rows.map(row => {
          prepareRow(row);
          return (
            <ChakraTable.Row {...row.getRowProps()} key={`issues-row-${row.id}`}>
              {row.cells.map((cell, index) => {
                let cellWidth;
                switch (index) {
                  case 0:
                    cellWidth = '30%';
                    break;
                  case 1:
                    cellWidth = '40%';
                    break;
                  case 2:
                    cellWidth = '15%';
                    break;
                  case 3:
                    cellWidth = '15%';
                    break;
                  default:
                    cellWidth = '25%';
                }
                return (
                  <ChakraTable.Cell 
                    {...cell.getCellProps()} 
                    style={{ 
                      width: cellWidth, 
                      overflowX: 'auto', 
                      whiteSpace: 'nowrap',
                      verticalAlign: 'top'
                    }} 
                    key={`issues-cell-${row.id}-${cell.column.id}`}
                  >
                    <Code size="sm">
                      <pre style={{ whiteSpace: 'pre-wrap' }}>{cell.render('Cell')}</pre>
                    </Code>
                  </ChakraTable.Cell>
                );
              })}
            </ChakraTable.Row>
          );
        })}
      </ChakraTable.Body>
    </ChakraTable.Root>
  );
};
