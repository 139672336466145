import React, { useMemo } from 'react';
import { Column, useTable } from "react-table";
import { Badge, Box, Table as ChakraTable, Icon, Text } from '@chakra-ui/react';
import { FaAngleDown } from "react-icons/fa6";
import { AggregatedRules, ErrorOrWarningOrObservation, Summary } from './types';

const mapStatus = (status: string, errorOrWarningOrObservation: ErrorOrWarningOrObservation | undefined): [string, string] => {
  if (!status) return ["Not Checked", "gray"];
  if (status === "passed") return ["Passed", "green"];
  if (errorOrWarningOrObservation === "observation") {
    return ["Report Generated", "gray"];
  }
  else if (errorOrWarningOrObservation === "error") {
    if (status === "failed") return ["Failed", "red"];
  }
  else if (errorOrWarningOrObservation === "warning5") {
    if (status === "failed") return ["Needs Your Attention", "orange"];
  }
  else if (errorOrWarningOrObservation === "warning1") {
    if (status === "failed") return ["Conditionally Passed", "yellow"];
  }
  return ["", "gray"];
};

export const Table: React.FC<{ data: Summary; rules: AggregatedRules; onRowClick: (row: any) => void  }> = ({ data, rules, onRowClick }) => {
  // Merge data and rules into a format suitable for react-table
  const tableData = useMemo(() => {
    let rows: { ruleOrCategory: string; errorOrWarningOrObservation: ErrorOrWarningOrObservation | undefined; description: string; status: string }[] = [];

    for (const [category, definedRules] of Object.entries(rules)) {
      // Add a category header row
      rows.push({
        ruleOrCategory: category,
        errorOrWarningOrObservation: undefined, 
        description: '', 
        status: '', 
      });

      // Add rows for each rule in the category
      Object.entries(definedRules).forEach(([rule, [errorOrWarning, description]]) => {
        rows.push({
          ruleOrCategory: rule,
          errorOrWarningOrObservation: errorOrWarning,
          description: description,
          status: data[rule], 
        });
      });
    }
    return rows;
  }, [data, rules]);

  const columns: Column<{ ruleOrCategory: string; errorOrWarningOrObservation: ErrorOrWarningOrObservation | undefined; description: string; status: string }>[] = useMemo(
    () => [
      {
        Header: 'Rule',
        accessor: 'ruleOrCategory',
        Cell: ({ row }) => {
          const { ruleOrCategory } = row.original;
          return <Text fontWeight="bold">{ruleOrCategory}</Text>;
        },
      },
      {
        Header: 'Description',
        accessor: 'description',
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ row }) => {
          const { errorOrWarningOrObservation, status } = row.original;
          const [renderedStatus, color] = mapStatus(status, errorOrWarningOrObservation);
          return (
            renderedStatus && renderedStatus !== 'Passed' && renderedStatus !== 'Not Checked' ?
            <Box cursor="pointer">
              <Badge colorPalette={color}>{renderedStatus}</Badge>
              <Icon fontSize="2xl" color="gray" size="sm">
                <FaAngleDown />
              </Icon>
            </Box>
            :
            <Badge colorPalette={color}>{renderedStatus}</Badge>
          );
        },
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows: tableRows,
    prepareRow,
  } = useTable({ columns, data: tableData });

  return (
    <ChakraTable.Root {...getTableProps()} size="sm" striped stickyHeader interactive variant="outline">
      <ChakraTable.Header>
        {headerGroups.map(headerGroup => (
          <ChakraTable.Row {...headerGroup.getHeaderGroupProps()} key="summary-header" bg="bg.subtle">
            {headerGroup.headers.map(column => (
              <ChakraTable.ColumnHeader {...column.getHeaderProps()} key={`summary-header-${column.id}`}>
                <Text fontWeight="bold">{column.render('Header')}</Text>
              </ChakraTable.ColumnHeader>
            ))}
          </ChakraTable.Row>
        ))}
      </ChakraTable.Header>
      <ChakraTable.Body {...getTableBodyProps()}>
        {tableRows.map(row => {
          prepareRow(row);
          const { status } = row.original;
          const isClickable = status === "failed" || status === "generated";
          return !(row.cells[1].value) ? (
              <ChakraTable.Row 
                {...row.getRowProps()} 
                key={`summary-row-${row.cells[0].value}`}
              >
                <ChakraTable.Cell
                  colSpan={3}
                  textAlign="center"
                  {...row.cells[0].getCellProps()}
                  key={`summary-cell-${row.cells[0].value}`}  
                >
                  {row.cells[0].render('Cell')}
                </ChakraTable.Cell>
              </ChakraTable.Row>
            ) : (
              <ChakraTable.Row 
                {...row.getRowProps()} 
                onClick={isClickable ? () => onRowClick(row.cells[0].value) : undefined}
                key={`summary-row-${row.cells[0].value}`}
              >
                {row.cells.map(cell => (
                  <ChakraTable.Cell
                    {...cell.getCellProps()}
                    key={`summary-cell-${row.cells[0].value}-${cell.column.id}`}
                  >
                    {cell.render('Cell')}
                  </ChakraTable.Cell>
                ))}
              </ChakraTable.Row>
            );
        })}
      </ChakraTable.Body>
    </ChakraTable.Root>
  );
};
