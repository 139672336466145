import React from 'react';
import { Box, Group, Text, Link, Flex, Image } from '@chakra-ui/react';

interface HeaderProps {
  schemaId?: string;
  schemaVersion?: string;
}

const Header: React.FC<HeaderProps> = ({ schemaId, schemaVersion }) => (
  <Box as="header" color="white" bg="#18181b" padding="10px">
    <Flex>
      <Box marginRight="10px">
        <Image src="https://mc.movielabs.com/static/78b75b22fd317edd14d9a6dba4b47290/logo_website_gradient_dark.svg" alt="MovieLabs Logo" style={{ height: '25px' }} />
      </Box>
        <Group>
          <Text fontWeight="bold">OMC Checker:</Text>
          <Text>Validate your OMC JSON files against</Text>
          {schemaId && schemaVersion && (
            <Link href={schemaId} target="_blank" color="white">
              <u>OMC-JSON Schema v{schemaVersion}</u>
            </Link>
          )}
          {!schemaId && !schemaVersion && (
            <Text>OMC-JSON Schema</Text>
          )}
        </Group>
    </Flex>
  </Box>
);

export default Header; 