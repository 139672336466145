import React from 'react';
import { Badge, Container, Heading } from '@chakra-ui/react';

interface PassOrFailProps {
  hasErrors: boolean;
  hasWarnings: boolean;
  data: any;
}

const PassOrFail: React.FC<PassOrFailProps> = ({ hasErrors, hasWarnings, data }) => {
  if (!data) return null;

  return (
    <>
      {!hasErrors && (
        <Container>
          {!hasWarnings && <Heading size="xl" padding="10px 0 10px 0"><Badge size="lg" colorPalette="green">Passed</Badge></Heading>}
          {hasWarnings && <Heading size="xl" padding="10px 0 10px 0"><Badge size="lg" colorPalette="yellow">Passed with Warnings</Badge></Heading>}
          <div style={{ paddingBottom: '40px' }} />
        </Container>
      )}
      {hasErrors && (
        <Container>
          <Heading size="xl" padding="10px 0 10px 0"><Badge size="lg" colorPalette="red">Failed</Badge></Heading>
          <div style={{ paddingBottom: '40px' }} />
        </Container>
      )}
    </>
  );
};

export default PassOrFail; 