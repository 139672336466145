import React, { useMemo } from 'react';
import { Column, useTable } from "react-table";
import { Table as ChakraTable, Text } from '@chakra-ui/react';

export const Table: React.FC<{ data: {[scope: string]: number}  }> = ({ data }) => {
  // Assuming data is just identifier count per scope
  const tableData = useMemo(() => {
    let rows: { scope: string; count: number }[] = [];

    for (const [scope, count] of Object.entries(data)) {
      rows.push({
        scope: scope,
        count: count,
      });
    }
    return rows;
  }, [data]);

  const columns: Column<{ scope: string; count: number }>[] = useMemo(
    () => [
      {
        Header: 'Identifier Scope',
        accessor: 'scope',
        Cell: ({ row }) => {
          const { scope } = row.original;
          return <Text fontWeight="bold">{scope}</Text>;
        },
      },
      {
        Header: 'Number of Identifiers',
        accessor: 'count',
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows: tableRows,
    prepareRow,
  } = useTable({ columns, data: tableData });

  return (
    <ChakraTable.Root {...getTableProps()} size="sm" striped stickyHeader variant="line">
      <ChakraTable.Header>
        {headerGroups.map(headerGroup => (
          <ChakraTable.Row {...headerGroup.getHeaderGroupProps()} bg="bg.subtle">
            {headerGroup.headers.map(column => (
              <ChakraTable.ColumnHeader {...column.getHeaderProps()}>
                <Text fontWeight="bold">{column.render('Header')}</Text>
              </ChakraTable.ColumnHeader>
            ))}
          </ChakraTable.Row>
        ))}
      </ChakraTable.Header>
      <ChakraTable.Body {...getTableBodyProps()}>
        {tableRows.map(row => {
          prepareRow(row);
          return (
              <ChakraTable.Row 
                {...row.getRowProps()} 
              >
                {row.cells.map(cell => (
                  <ChakraTable.Cell
                    {...cell.getCellProps()}
                  >
                    {cell.render('Cell')}
                  </ChakraTable.Cell>
                ))}
              </ChakraTable.Row>
            );
        })}
      </ChakraTable.Body>
    </ChakraTable.Root>
  );
};
