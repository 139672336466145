import React from 'react';
import { Container, Stack, HStack, Skeleton } from '@chakra-ui/react';
import { SkeletonCircle, SkeletonText } from './components/ui/skeleton';

const Loading: React.FC = () => (
  <Container>
    <Stack gap="6" maxW="xs">
      <HStack width="full">
        <SkeletonCircle size="10" />
        <SkeletonText noOfLines={2} />
      </HStack>
      <Skeleton height="200px" />
    </Stack>
  </Container>
);

export default Loading; 